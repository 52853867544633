<template>
  <div class="home">
    <div class="benner" id="benner">
      <img src="./img/banner.png" alt=""/>
      <div class="header">
        <img src="./img/logo.png" />
        <div class="btnContent">
          <span @click="go('benner')">
            首页
          </span>
          <span @click="go('aboutUs')">
            关于我们
          </span>
          <span @click="go('service')">
            服务
          </span>
          <span @click="go('parner')">
            合作伙伴
          </span>
        </div>
      </div>
    </div>
    <div class="aboutUs" id="aboutUs">
      <img src="./img/aboutUs.png"/>
    </div>
    <div class="text" id="service">
      <span>我们的服务</span>
    </div>
    <div class="service">
      <img src="./img/service1.png" alt=""/>
      <img src="./img/service2.png" alt=""/>
      <img src="./img/service3.png" alt=""/>
      <img src="./img/service4.png" alt=""/>
      <img src="./img/service5.png" alt=""/>
      <img src="./img/service6.png" alt=""/>
    </div>
    <div class="parner" id="parner">
      <img src="./img/parner.png" alt="">
    </div>
    <div class="text2">
      <div class="one">联系我们</div>
      <div class="two">您可以发邮件给我们，详细描述您的问题：</div>
      <div class="three">marketing@evscloud.cn</div>
    </div>
    <div class="foot">
      <img @click="go('benner')" src="./img/logo.png" />
      <span>“Copyright      2023 evscloud.All Rights Reserved</span>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    go(id){
      document.getElementById(id).scrollIntoView()
    }
  }
}
</script>

<style lang="scss">
  .home{
    background-color: #fff;
    overflow: auto;
    .benner{
      width:100%;
      position: relative;
      img{
        width: 100%;
      }
      .header{
        position: absolute;
        top: 0;
        left: 12.5%;
        width: calc(100% - 25%);
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
          height: 60px;
          width: auto;
        }
        .btnContent{
          span{
            margin-left: 80px;
            color: #D4D5D7;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
    }
    .aboutUs{
      img{
        width: 100%;
      }
    }
    .text{
      height: 200px;
      width: 100%;
      text-align: center;
      padding-top: 100px;
      box-sizing: border-box;
      span{
        color: #202123;
        font-size: 36px;
      }
    }
    .service{
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 12%;
      img{
        margin-bottom: 40px;
        width: calc(50% - 16px);
      }
      margin-bottom: 110px;
    }
    .parner{
      img{
        width: 100%;
      }
    }
    .text2{
      height: 207px;
      width: 100%;
      padding-top: 65px;
      box-sizing: border-box;
      text-align: center;
      .one{
        font-size: 32px;
        color: #202123;
        margin-bottom: 12px;
      }
      .two{
        font-size: 16px;
        color: #5B6065;
        margin-bottom: 12px;
      }
      .three{
        font-size: 16px;
        color: #202123;
      }
    }
    .foot{
      background-color: #000227;
      padding: 0 12.5%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img{
        height: 60px;
        width: auto;
        cursor: pointer;
      }
      span{
        font-size: 12px;
        color: #7C7D8F;
      }
    }
  }
</style>